import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";

import {
  ParallaxProvider,
  ParallaxBanner,
  ParallaxBannerLayer,
} from "react-scroll-parallax";

import { isMobile, isTablet } from "react-device-detect";

//pdf
function Faqs() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  function isMobileOrTablet() {
    return isMobile || isTablet;
  }
  return (
    <>
      <div className="privacy-policy ">
        <header className="bg-dark text-white text-center py-5">
          <h1 className="display-4">FAQ's</h1>
        </header>
        <div className="container mt-5">
          <div className="content">
            <h2>Introduction</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ut
              purus eget nunc tincidunt commodo.
            </p>
          </div>
          <div className="content">
            <h2>Information Collection and Use</h2>
            <p>
              Nullam euismod, neque a viverra feugiat, elit enim sodales turpis,
              nec interdum lorem eros id purus.
            </p>
          </div>
          <div className="content mb-5"></div>
        </div>
      </div>
    </>
  );
}
export { Faqs };
