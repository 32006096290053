import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Routes,
  Route,
  NavLink,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Link } from "react-scroll";
//pages
import { Home } from "../components/home";
import { PrivacyPolicy } from "../components/privacyPolicy";
import { Faqs } from "../components/faqs";
import { TermsConditions } from "../components/termsConditions";
import { NotFound } from "../components/not-found";

function Menu() {
  const logo = require("../../assets/img/logo/Logo-bondi.png");
  const whatsapp = require("../../assets/img/design/whatsapp.png");

  const [menuMobile, setMenuMobile] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  //detect scroll
  const [isScrolled, setIsScrolled] = useState(false);
  const [isEndOfPage, setIsEndOfPage] = useState(false);
  const [isEndOfPageWhatsApp, setIsEndOfPageWhatsApp] = useState(false);

  //detect location
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const documentHeight = document.documentElement.scrollHeight;
      const position = window.pageYOffset;
      const windowHeight = window.innerHeight;

      if (position > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
      let sumPosition = position + windowHeight + 200;
      if (sumPosition > documentHeight) {
        setIsEndOfPage(true);
      } else {
        setIsEndOfPage(false);
      }
      let sumPositionWhatsApp = position + windowHeight + 100;
      // console.log(documentHeight, sumPositionWhatsApp);
      if (sumPositionWhatsApp > documentHeight) {
        setIsEndOfPageWhatsApp(true);
      } else {
        setIsEndOfPageWhatsApp(false);
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [menuMobile]);

  const handleScrollUp = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleMenuMobile = (evento, id) => {
    if (location.pathname === "/" && id) {
      setMenuMobile(evento);
    } else {
      if (id) {
        navigate("/");
        setTimeout(() => {
          const sectionElement = document.getElementById(id);
          if (sectionElement) {
            setMenuMobile(evento);
            sectionElement.scrollIntoView({ behavior: "smooth" });
          }
        }, 100);
      } else {
        setMenuMobile(evento);
      }
    }
  };
  const handleLinkClickClose = () => {
    // Close the mobile menu when a link is clicked
    setShowMenu(false);
  };
  const handleLinkClickOpen = () => {
    // Close the mobile menu when a link is clicked
    setShowMenu(true);
  };

  return (
    <>
      <section>
        <div className="menu">
          <nav
            className={`navbar navbar-expand-lg  fixed-top ${
              menuMobile ? "mobile" : "desktop"
            }`}
          >
            <div className="container-fluid">
              <Link
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={() => handleMenuMobile(false, "home")}
                className="navbar-brand"
              >
                <img className="img-fluid" src={logo} alt="Logo" />
              </Link>
              {menuMobile ? (
                <button
                  className="navbar-toggler"
                  onClick={() => handleMenuMobile(false)}
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <div className="line-btn-x">
                    <span className=""></span>
                    <span className=""></span>
                  </div>
                </button>
              ) : (
                <button
                  className="navbar-toggler"
                  onClick={() => handleMenuMobile(true)}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <div className="line-btn">
                    <span className=""></span>
                    <span className=""></span>
                    <span className=""></span>
                  </div>
                </button>
              )}

              <div
                className={`collapse navbar-collapse justify-content-end  ${
                  menuMobile ? "show" : "false"
                }`}
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <Link
                      activeClass="active"
                      to="home"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      className="nav-link"
                      onClick={() => handleMenuMobile(false, "home")}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      activeClass="active"
                      to="services"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      className="nav-link"
                      onClick={() => handleMenuMobile(false, "services")}
                    >
                      Our Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      activeClass="active"
                      to="aboutdna"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      className="nav-link"
                      onClick={() => handleMenuMobile(false, "aboutdna")}
                    >
                      About
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      activeClass="active"
                      to="contact"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      className="nav-link"
                      onClick={() => handleMenuMobile(false, "contact")}
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
                <div className="icon">
                  <a
                    className="nav-link"
                    href="https://www.instagram.com/bondimedspa/"
                    target="_blank"
                  >
                    <span>
                      {" "}
                      <i className="fa-brands fa-instagram"></i>
                    </span>
                  </a>
                  <a
                    className="nav-link"
                    href="https://www.facebook.com/bondimedspa"
                    target="_blank"
                  >
                    <span>
                      {" "}
                      <i className="fa-brands fa-facebook-f"></i>
                    </span>
                  </a>
                  <a
                    className="nav-link"
                    href="https://www.linkedin.com/company/bondimedspa"
                    target="_blank"
                  >
                    <span>
                      {" "}
                      <i className="fa-brands fa-linkedin-in"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </section>
      {isEndOfPageWhatsApp ? (
        <div className="whatsapp-fixed">
          <NavLink
            to={"https://wa.me/+13053605034"}
            target="_blank"
            className="d-flex justify-content-start"
          >
            <img src={whatsapp} />
          </NavLink>
        </div>
      ) : (
        <div className="whatsapp ">
          <NavLink
            to={"https://wa.me/+13053605034"}
            target="_blank"
            className="d-flex justify-content-start"
          >
            <img src={whatsapp} />
          </NavLink>
        </div>
      )}
      {isEndOfPage ? (
        <div>
          <NavLink className="btn-up" onClick={handleScrollUp}>
            <i className="fa fa-angle-up"></i>
          </NavLink>
        </div>
      ) : (
        <div>{isEndOfPageWhatsApp ? <></> : <></>}</div>
      )}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-conditions" element={<TermsConditions />} />
        <Route path="faqs" element={<Faqs />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export { Menu };
