import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";

import {
  ParallaxProvider,
  ParallaxBanner,
  ParallaxBannerLayer,
} from "react-scroll-parallax";

import { isMobile, isTablet } from "react-device-detect";

//pdf
function NotFound() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  function isMobileOrTablet() {
    return isMobile || isTablet;
  }
  return (
    <>
      <div className="not-found">
        <div className="header">
          <h1 className="display-4">
            404
            <br />
            Page Not Found
          </h1>
        </div>
      </div>
    </>
  );
}
export { NotFound };
