import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";

import {
  ParallaxProvider,
  ParallaxBanner,
  ParallaxBannerLayer,
} from "react-scroll-parallax";

import { isMobile, isTablet } from "react-device-detect";

//pdf
function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  function isMobileOrTablet() {
    return isMobile || isTablet;
  }
  return (
    <>
      <div className="privacy-policy ">
        <header className="bg-dark text-white text-center py-5">
          <h1 className="display-4">Privacy Policy for Bondi Med Spa</h1>
        </header>
        <div className="container mt-5">
          <div className="content">
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iure
              nulla repudiandae itaque sapiente reprehenderit incidunt aperiam,
              in ut laborum quos, maxime tempora minus id impedit sunt. Non
              aliquam cupiditate vero!
            </p>
          </div>
          <div className="content">
            <h2>Lorem, Ipsum</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti,
              veritatis esse dignissimos nulla, et minima nostrum fuga officia
              labore fugiat incidunt ipsam. Maiores repellendus similique
              facilis nostrum! Ipsa, laboriosam. Voluptatum!
            </p>
          </div>

          <div className="content mb-5"></div>
        </div>
      </div>
    </>
  );
}
export { PrivacyPolicy };
