import React from 'react';
import { BrowserRouter, Routes } from 'react-router-dom';

// Import components
import { Menu } from './pages/layouts/menu';
import { Footer } from './pages/layouts/footer';

function App() {
  return (
    <BrowserRouter >
      {/* <Authprovider> */}
      {/* </Authprovider> */}
      <Menu />
      <Routes>
        {/* Define your routes here */}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;