import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";

import {
  ParallaxProvider,
  ParallaxBanner,
  ParallaxBannerLayer,
} from "react-scroll-parallax";

import { isMobile, isTablet } from "react-device-detect";

//pdf
function TermsConditions() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  function isMobileOrTablet() {
    return isMobile || isTablet;
  }
  return (
    <>
      <div className="privacy-policy ">
        <header className="bg-dark text-white text-center py-5">
          <h1 className="display-4">Termns And Conditions</h1>
        </header>
        <div className="container mt-5">
          <div className="content">
            <p>Welcome to Bondi Med Spa!</p>
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. In
              veniam nulla quae illum. Excepturi qui expedita ipsam laborum
              maiores quidem unde suscipit debitis dicta? Quam laudantium autem
              unde. Eveniet, deleniti.
            </p>
          </div>
          <div className="content">
            <h2>Lorem, Ipsum</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste
              voluptas velit dolor? Commodi, in dolore. Nesciunt laboriosam
              molestiae porro quibusdam voluptas ducimus, animi perferendis
              doloremque officia at, blanditiis nostrum voluptate?
            </p>
            <ul>
              <li>Lorem, Ipsum</li>
              <li>Lorem, Ipsum</li>
            </ul>
          </div>

          <div className="content mb-5"></div>
        </div>
      </div>
    </>
  );
}
export { TermsConditions };
