import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";

import {
  ParallaxProvider,
  ParallaxBanner,
  ParallaxBannerLayer,
} from "react-scroll-parallax";

import { Carousel } from "bootstrap";

import { isMobile, isTablet } from "react-device-detect";

//email js
import emailjs from "emailjs-com";

//formik
import { Field, useFormik } from "formik";
import * as Yup from "yup";

//bootstrap
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import axios from "axios";

//validation form
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  message: Yup.string().required("Required"),
});

function Home() {
  const slide1 = require("../../assets/img/test_img/depilacion-laser-terapia-depilacion.jpg");
  const slide1Mobile = require("../../assets/img/slide/mobile/Banner-home.jpg");

  /* arrows slide 2 */
  const r = require("../../assets/img/design/rigth.png");
  const l = require("../../assets/img/design/left.png");

  //ceo
  const element_ceo = require("../../assets/img/about/element-ceo.png");

  //spinner
  const [isLoadMessage, setIsLoadMessage] = useState(false);
  //banner
  const b1 = require("../../assets/img/banner/desktop/Banner-home.jpg");
  const b2 = require("../../assets/img/banner/desktop/Banner Body Contouring.jpg");
  const b3 = require("../../assets/img/banner/desktop/Banner Facial Treatments.jpg");
  const b4 = require("../../assets/img/banner/desktop/Banner Injectables.jpg");
  const b5 = require("../../assets/img/banner/desktop/Banner Weightloss program.jpg");
  const b6 = require("../../assets/img/banner/desktop/Banner-Laser-Treatments.jpg");
  /* banner mobile */
  const b1_m = require("../../assets/img/banner/mobile/Banner-home-movil.jpg");
  const b2_m = require("../../assets/img/banner/mobile/Banner-Body-Contouring-movil.jpg");
  const b3_m = require("../../assets/img/banner/mobile/Banner-Facial-Treatments-movil.jpg");
  const b4_m = require("../../assets/img/banner/mobile/Banner-Injectables-movil.jpg");
  const b5_m = require("../../assets/img/banner/mobile/Banner-Weightloss-program-movil.jpg");
  const b6_m = require("../../assets/img/banner/mobile/Banner-Laser-Treatments.jpg");

  /* elements services */
  const s1 = require("../../assets/img/design/3.png");
  const s4 = require("../../assets/img/design/4.png");
  const s1_mobile = require("../../assets/img/design/mobile/4.png");
  const s2_mobile = require("../../assets/img/design/mobile/2.png");
  const s7_mobile = require("../../assets/img/design/mobile/7.png");
  const s1_1_mobile = require("../../assets/img/design/mobile/1.png");
  const s6_mobile = require("../../assets/img/design/mobile/6.png");

  /* services */
  const service1 = require("../../assets/img/services/desktop/Laser-treatments.jpg");
  const service1_m1 = require("../../assets/img/services/mobile/Laser-treatments-movil.png");

  const service2 = require("../../assets/img/services/desktop/Injectables.jpg");
  const service2_m2 = require("../../assets/img/services/mobile/Injectables-movil.png");

  const service3 = require("../../assets/img/services/desktop/Body-Contouring.jpg");
  const service3_m3 = require("../../assets/img/services/mobile/Body-Contouring-movil.png");

  const service4 = require("../../assets/img/services/desktop/Weightloss-program.jpg");
  const service4_m4 = require("../../assets/img/services/mobile/Weightloss-program-movil.png");

  const service5 = require("../../assets/img/services/desktop/Facial-Treatments.jpg");
  const service5_m5 = require("../../assets/img/services/mobile/Facial-Treatments-Movil.png");

  /* about */
  const ceo = require("../../assets/img/about/Img-profile.png");

  //detect scroll
  const [isScrolled, setIsScrolled] = useState(false);
  const [showWhatsAppButton, setShowWhatsAppButton] = useState(false);

  //instagram
  const [posts, setPosts] = useState([]);
  const [token, setToken] = useState(
    "IGQWRQUDdRdUc5dWthM2RlUTFnVXlQaDlieTV0LXQ2YXBEeEtDMUtUN0k0UVlKSnpSSzlubkZAhY2l1TFhqMmpYZAHJsZAlNhSGpQZAUdxTzc5QWY0bVpzWjlZAVEpIVzZAJLW1vSTgxTG0xWHJxV3VNbXA1NlNGOGxLazAZD"
  );
  //modal
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  //detect scree
  const [isMobileBd, setIsMobileBd] = useState(window.innerWidth < 768);

  //message modal
  const [messageContact, setMessageContact] = useState("");

  useEffect(() => {
    //detect screen
    var detectSize = window.innerWidth < 768;
    setIsMobileBd(detectSize);
    //get images instagram
    accessImages();

    //automatic slide tablet or mobile
    const carousel = new Carousel(document.querySelector(".carousel"), {
      interval: isMobileOrTablet() ? 5000 : 3000,
    });
    //btn whatsapp
    function handleScroll() {
      const documentHeight = document.documentElement.scrollHeight;
      const position = window.pageYOffset;
      const windowHeight = window.innerHeight;
      let sumPosition = position + windowHeight + 200;

      if (window.scrollY > 10) {
        setShowWhatsAppButton(true);
      } else {
        setShowWhatsAppButton(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  /* homer banner */
  const bannerData = [
    {
      active: 1,
      img: isMobileBd ? b6_m : b6,
      img_mobile: b6_m,
      title: "Laser Hair Removal",
      description:
        "Smooth skin awaits! Ditch razors, try our laser removal. Book now!",
      btn: "Call Now",
      btn_action: "+13053605034",
    },
    {
      active: 0,
      img: isMobileBd ? b2_m : b2,
      img_mobile: b2_m,
      title: "Weight Loss Program",
      description:
        "Transform your life with our tailored weight loss program. Shed those extra pounds and embrace a healthier, happier you. Start your journey to a slimmer you now!",
      btn: "",
      btn_action: "",
    },
    {
      active: 0,
      img: isMobileBd ? b3_m : b3,
      img_mobile: b3_m,
      title: "Facials",
      description:
        "Indulge in our rejuvenating facials. Unlock your skin’s natural radiance and relax in luxury. Elevate  your skincare routine with our expert treatments.",
      btn: "",
      btn_action: "",
    },
    /*  {
      active: 0,
      img: isMobileBd ? b4_m : b4,
      img_mobile: b4_m,
      title: "Botox and Fillers",
      description:
        "Reveal a more youthful you with our Botox and filler treatments. Smooth away wrinkles and enhance your natural beauty. Trust our skilled professionals for stunning results.",
      btn: "",
      btn_action: "",
    }, */
    {
      active: 0,
      img: isMobileBd ? b5_m : b5,
      img_mobile: b5_m,
      title: "Body Contouring Program",
      description:
        "Sculpt your dream body with our body contouring program. Say hello to a more defined silhouette. Unleash your confidence with our targeted treatments.",
      btn: "",
      btn_action: "",
    },
  ];
  /* services */
  const servicesData = [
    {
      active: 1,
      img: isMobileBd ? service1_m1 : service1,
      list: {
        t1: "Hair removal ",
        t2: "Veins removal ",
        t3: "Nail fungus removal",
      },
      title: "Laser treatments:",
      content:
        "Whether it's unwanted hair, spider veins, or nail fungus, we have the solution you need. Your journey to freeing yourself from these begins here.",
    },
    /*  {
      active: 0,
      img: isMobileBd ? service2_m2 : service2,
      list: { t1: "Fillers", t2: "Botox ", t3: "IV therapy " },
      title: "injectables:",
      content:
        "Reveal youthful radiance! Our expertly administered injectables smooth fine lines, enhance features, and rejuvenate your skin for a stunning, natural glow.",
    }, */
    {
      active: 0,
      img: isMobileBd ? service3_m3 : service3,
      list: {
        t1: "⁠Fractional Radio Frequency",
        t2: "Carboxytherapy ",
        t3: "⁠⁠Wood Theraphy ",
      },
      title: "Body Contouring:",
      content:
        "Sculpt your dream body! Our Body Contouring services redefine and reshape, offering you the confidence you've always wanted.",
    },
    {
      active: 0,
      img: isMobileBd ? service4_m4 : service4,
      list: { t1: "Semaglutide", t2: "Lipotropics ", t3: "Vitamins " },
      title: "Weightloss Program:",
      content:
        "Embark on a transformative journey with our Weight Loss Programs. Tailored to you, for a healthier, happier life.",
    },
    {
      active: 0,
      img: isMobileBd ? service5_m5 : service5,
      list: {
        t2: "Skin Tightening ",
        t3: "Facials ",
        t4: "Carbon Peel",
      },
      title: "Facial Treatments:",
      content:
        "Illuminate your natural beauty! Our specialized Facial Treatments rejuvenate, refresh, and restore your radiant glow.",
    },
  ];

  //instagram
  function accessImages() {
    const apiUrl = `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink,timestamp&access_token=${token}&limit=4&order=desc`;
    //console.log(apiUrl);
    axios
      .get(apiUrl)
      .then((response) => {
        setPosts(response.data.data);
      })
      .catch((error) => {
        refresh_access_token();
      });
  }

  function refresh_access_token() {
    const urlRefreshToken = `https://graph.instagram.com/refresh_access_token?grant_type=ig_refresh_token&access_token=${token}`;
    axios
      .get(urlRefreshToken)
      .then((response) => {
        if (response.data.access_token) {
          accessImages();
        }
      })
      .catch((error) => {});
  }
  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }
  //detect mobile tablet
  function isMobileVideo() {
    if (isMobile && !isTablet) {
      return isMobile;
    }
  }
  function isMobileOrTablet() {
    return isMobile || isTablet;
  }
  const onMessage = async (values) => {
    const formattedMessage = values.message.replace(/\n/g, "<br/>");
    let data = {
      name: values.name,
      email: values.email,
      message: formattedMessage,
    };
    const url = "http://localhost:4506/api/page/contact/bondi";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    try {
      setIsLoadMessage(true);
      const response = await fetch(url, options);
      const responseData = await response.json();
      if (responseData.statusCode === "200") {
        setIsLoadMessage(false);
        setMessageContact("Message sent successfully!.");
        formik.resetForm();
        setShow(true);
      } else {
        setIsLoadMessage(false);
        setMessageContact("An error occurred, please try again!.");
        setShow(true);
      }
    } catch (error) {
      setIsLoadMessage(false);
      setMessageContact("An error occurred, please try again!.");
      setShow(true);
    }
  };

  //formik
  const formik = useFormik({
    initialValues: { name: "", email: "", message: "" },
    validateOnBlur: true,
    onSubmit: (values) => {
      onMessage(values);
    },
    validationSchema: validationSchema,
  });

  return (
    <>
      <section className="home-banner" id="home">
        <div
          id="carouselExampleInterval"
          className="carousel slide carousel-dark "
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            {bannerData.length > 0 ? (
              bannerData.map((item, i) => {
                return (
                  <button
                    key={i}
                    type="button"
                    data-bs-target="#carouselExampleInterval"
                    className={`${item.active === 1 ? "active" : ""}`}
                    aria-current={`${item.active === 1 ? "true" : ""}`}
                    data-bs-slide-to={i}
                    aria-label={i + 1}
                  ></button>
                );
              })
            ) : (
              <></>
            )}
          </div>
          <div className="carousel-inner">
            {bannerData.length > 0 ? (
              bannerData.map((item, i) => {
                return (
                  <div
                    key={i}
                    className={`carousel-item   ${
                      item.active === 1 ? "active" : ""
                    }`}
                    data-bs-interval="300000"
                  >
                    <img src={item.img} className="d-block w-100" alt="..." />
                    <div
                      className={` ${
                        item.btn
                          ? "carousel-caption content-btn "
                          : "carousel-caption"
                      } d-md-block `}
                    >
                      <h5 className="display-1">{item.title}</h5>
                      <p
                        className="display-4"
                        style={{ width: `${i === 2 ? "45%" : "auto"}` }}
                      >
                        {item.description}
                      </p>
                      {item.btn ? (
                        <div className="center-btn">
                          <a
                            className="btn btn-banner"
                            href={`tel:${item.btn_action}`}
                          >
                            <i className="fa-solid fa-phone"></i>
                            {item.btn}
                          </a>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
          <button
            className="carousel-control-prev carousel-control carousel-control-left d-none d-lg-block "
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide="prev"
          >
            <span className="carousel-control-icon" aria-hidden="true">
              <i className="fa-solid fa-chevron-left"></i>
            </span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next carousel-control d-none d-lg-block"
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide="next"
          >
            <span className="carousel-control-icon" aria-hidden="true">
              <i className="fa-solid fa-chevron-right"></i>
            </span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section>
      {/* slide #2 */}
      <section className="home-banner-2 mt-3 bg-slide-2" id="services">
        <div id="carousel-2" className="carousel slide carousel-dark ">
          <div className="carousel-indicators">
            {servicesData.length > 0 ? (
              servicesData.map((item, i) => {
                return (
                  <button
                    key={i}
                    type="button"
                    data-bs-target="#carousel-2"
                    className={`${item.active === 1 ? "active" : ""}`}
                    aria-current={`${item.active === 1 ? "true" : ""}`}
                    data-bs-slide-to={i}
                    aria-label={i + 1}
                  ></button>
                );
              })
            ) : (
              <></>
            )}
          </div>
          <div className="carousel-inner">
            {servicesData.length > 0 ? (
              servicesData.map((item, i) => {
                return (
                  <div
                    key={i}
                    className={`carousel-item ${
                      item.active === 1 ? "active" : ""
                    }`}
                  >
                    <div className="box-content">
                      <img
                        className="img-design-2"
                        src={isMobileBd ? s2_mobile : ""}
                      />
                      <img
                        className="img-design-2"
                        src={isMobileBd ? s7_mobile : ""}
                      />
                      <img
                        className="img-design-6"
                        src={isMobileBd ? s6_mobile : ""}
                      />
                      <div className="img-bg">
                        <img
                          className="img-design"
                          src={isMobileBd ? s1_mobile : s1}
                        />
                        <img
                          className="img-design-1"
                          src={isMobileBd ? s1_1_mobile : ""}
                        />
                        <img
                          className="img-design-4"
                          src={isMobileBd ? "" : s4}
                        />
                        <img className="img-services" src={item.img} />
                        <svg
                          viewBox="0 30 500 500"
                          preserveAspectRatio="xMinYMin meet"
                        >
                          <path d="M0,200 C200,300 300,0 500,200 L500,00 L0,0 Z"></path>
                        </svg>
                      </div>
                      <div className="description">
                        <ul className="list">
                          {Object.entries(item.list).map(([key, value], i2) => {
                            return <li key={i2}>{`${value}`}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="carousel-caption d-md-block">
                      <h5 className="display-1">{item.title}</h5>
                      <p className="display-4">{item.content}</p>
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
          <button
            className="carousel-control-prev carousel-control carousel-control-left "
            type="button"
            data-bs-target="#carousel-2"
            data-bs-slide="prev"
          >
            <span className="carousel-control-icon" aria-hidden="true">
              <img className="arrow-slide" src={l} />
            </span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next carousel-control"
            type="button"
            data-bs-target="#carousel-2"
            data-bs-slide="next"
          >
            <span className="carousel-control-icon" aria-hidden="true">
              <img className="arrow-slide" src={r} />
            </span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section>
      {/* instagram section */}
      <section className="instagram">
        <div className="container">
          <div className="title">
            <h6>follow us @bondimedspa</h6>
            <div className="icons">
              <a
                className="nav-link"
                href="https://www.instagram.com/bondimedspa/"
                target="_blank"
              >
                <span>
                  <i className="fa-brands fa-instagram"></i>
                </span>
              </a>
              <a
                className="nav-link"
                href="https://www.facebook.com/bondimedspa"
                target="_blank"
              >
                <span>
                  <i className="fa-brands fa-facebook-f"></i>
                </span>
              </a>
              <a
                className="nav-link"
                href="https://www.linkedin.com/company/bondimedspa/"
                target="_blank"
              >
                <span>
                  <i className="fa-brands fa-linkedin-in"></i>
                </span>
              </a>
            </div>
          </div>
          <div className="row">
            {posts.length > 0 ? (
              posts.map((item, i) => (
                <div className="col-sm-12 col-md-6 col-lg-3 card mb-3" key={i}>
                  <div className="content-box">
                    {item.media_type === "IMAGE" ||
                    item.media_type === "CAROUSEL_ALBUM" ? (
                      <img
                        src={item.media_url}
                        className="card-img-top"
                        alt="..."
                      />
                    ) : item.media_type === "VIDEO" ? (
                      <video controls className="card-img-top">
                        <source src={item.media_url} type="video/mp4" />
                      </video>
                    ) : null}
                    <div className="card-body">
                      <h5>Bondi Med Spa</h5>
                      <div className="card-content">
                        <p className="card-text ">
                          {formatDate(item.timestamp)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-sm-12 col-md-12 not-post">
                <h2>
                  No Posts Yet{" "}
                  <i className="fa-solid fa-triangle-exclamation"></i>
                </h2>
              </div>
            )}
          </div>
          <div
            className="gallery"
            style={{ display: posts.length > 0 ? "" : "none" }}
          >
            <a
              href="https://www.instagram.com/bondimedspa/"
              target="_blank"
              className="btn"
            >
              view gallery
            </a>
          </div>
        </div>
      </section>

      <section className="mt-3 about" id="aboutdna">
        <div className="blur-about">
          <div className="container">
            <div className="description">
              <h5>ABOUT US</h5>
              <p className="">
                Discover the essence of beauty and wellness at Bondi Med Spa,
                where personalized care meets innovative treatments. Our quest
                is to harmonize artistic expression with the newest in medical
                advancements, delivering long lasting results. Whether it’s
                through rejuvenating injectables, meticulous body contouring,
                comprehensive weight management, or luxurious facial treatments,
                our offerings are crafted to your needs. We believe in promoting
                best practices, providing each client with a journey marked by
                quality and attention to detail. Our vision is to nurture your
                natural beauty, boost confidence, and cultivate well-being in a
                space dedicated to professionalism and calm. Join us on a path
                of rejuvenation and radiance, where every session is a
                celebration of your unique beauty.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="ceo">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6  bg-img-ceo">
            <div className="container">
              <div className="img-ceo">
                <img src={ceo} className="img-fluid" alt="Yudelkis  Espinosa" />
              </div>
              <div className="name">
                <h5>Yudelkis Espinosa</h5>
                <p>Founder and CEO</p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 description ">
            <div>
              <div className="d-flex justify-content-end element-img">
                <img className="img-com" src={element_ceo} />
              </div>
              <p>
                At Bondi Medical Spa, we are dedicated to sculpting beauty and
                wellness through innovative, tailored treatments. Our mission is
                to blend artistry with the latest medical advancements to offer
                transformative experiences, from revitalizing injectables and
                precision body contouring to comprehensive weight-loss programs
                and luxurious facial treatments. We believe in promoting best
                practices, ensuring each client receives personalized,
                high-quality care.
              </p>
              <p>
                My expertise as registered nurse and aesthetician with the
                support of an outstanding team ensures that your transformation
                at Bondi Med Spa isn't just about appearance—it's a delicate
                balance where comfort, innovation, and elegance converge. Here,
                we don't just enhance your beauty; we rejuvenate your essence.
                So, step into our sanctuary, and let's unveil the most exquisite
                version of you, together.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className=" bg-contact " id="contact">
        <div className="container contact">
          <div className="row">
            <h5 className=""> REQUEST INFORMATION</h5>
            <form
              className="d-flex justify-content-center align-items-center"
              onSubmit={formik.handleSubmit}
            >
              <div className="row">
                <div className="col-12 col-lg-6 mb-3">
                  <input
                    type="text"
                    className={`form-control requiredField  ${
                      formik.errors.name && formik.touched.name
                        ? "text-danger"
                        : ""
                    }`}
                    placeholder={
                      formik.errors.name && formik.touched.name
                        ? "*" + "Name " + formik.errors.name
                        : "Name"
                    }
                    id="name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="col-12 col-lg-6 mb-3">
                  <input
                    type="text"
                    className={`form-control requiredField  ${
                      formik.errors.email && formik.touched.email
                        ? "text-danger"
                        : ""
                    }`}
                    placeholder={
                      formik.errors.email && formik.touched.email
                        ? "*" + "Email " + formik.errors.email
                        : "Email"
                    }
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="col-md-12 col-lg-12 mb-3">
                  <textarea
                    type="text"
                    className={`form-control requiredField  ${
                      formik.errors.message && formik.touched.message
                        ? "text-danger"
                        : ""
                    }`}
                    placeholder={
                      formik.errors.message && formik.touched.message
                        ? "*" + "Message " + formik.errors.message
                        : "Message"
                    }
                    id="message"
                    name="message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="col-12 text-center">
                  {isLoadMessage ? (
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <button
                      type="submit"
                      disabled={!formik.isValid}
                      className="btn btn-send"
                    >
                      Contact Us
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className=" modal"
      >
        <div className="modal-content content">
          <div className="modal-body">
            <p className="text-center"> {messageContact}</p>
          </div>
          <div className="modal-footer footer-login-modal  d-md-block  text-center">
            <button
              onClick={handleClose}
              type="button"
              className="btn btn-sm btn-send "
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
export { Home };
