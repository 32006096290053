import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, NavLink } from "react-router-dom";

import {
  ParallaxProvider,
  ParallaxBanner,
  ParallaxBannerLayer,
} from "react-scroll-parallax";
import logo from "../../assets/img/logo/Logo-footer.png";
import charlieapps from "../../assets/img/logo/logo_Charlie.png";

import { isMobile, isTablet } from "react-device-detect";

//pdf
function Footer() {
  function isMobileOrTablet() {
    return isMobile || isTablet;
  }
  return (
    <>
      <footer className="bg-dark-footer footer bg-pattern-footer">
        <div className="icon-footer d-flex">
          <a
            className="nav-link"
            href="https://www.instagram.com/bondimedspa/"
            target="_blank"
          >
            <span>
              <i className="fa-brands fa-instagram"></i>
            </span>
          </a>
          <a
            className="nav-link"
            href="https://www.facebook.com/bondimedspa"
            target="_blank"
          >
            <span>
              <i className="fa-brands fa-facebook-f"></i>
            </span>
          </a>
          <a
            className="nav-link"
            href="https://www.linkedin.com/company/bondimedspa"
            target="_blank"
          >
            <span>
              <i className="fa-brands fa-linkedin-in"></i>
            </span>
          </a>
        </div>
        <div className="container">
          <div className="footer-container">
            <div className="row d-flex justify-content-center">
              <div className="col-sm-12 col-md-12 col-lg-4 colum order-lg-1 order-3 menuu mb-3 dashed-border-none p-3">
                <ul className="list-unstyled">
                  <li className="brown-text font-45 ">
                    <Link to={"privacy-policy"}>Privacy Policy</Link>
                  </li>
                  <li className="brown-text font-45 ">
                    <Link to={"terms-conditions"}>Terms and Conditions</Link>
                  </li>
                  {/* <li className='brown-text font-45 '><Link to={'faqs'}>FAQ's</Link></li> */}
                </ul>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 colum text-center order-lg-2 order-1 dashed-border mb-3 p-2">
                <img src={logo} alt="Logo" />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 colum order-lg-3 order-2 dashed-border p-1">
                <div className="contact">
                  <ul className="list-unstyled">
                    <li className="brown-text font-45">
                      <div className="icon-text">
                        <i className="fa-solid fa-phone"></i> +1 305 360 50 34
                      </div>
                    </li>
                    <li className="brown-text font-45">
                      <div className="icon-text">
                        <i className="fa-solid fa-envelope"></i>
                        info@bondimedspa.com
                      </div>
                    </li>
                    <li className="brown-text font-45">
                      <div className="icon-text">
                        <i className="fa-solid fa-location-dot"></i>1990 SW 27th
                        Ave , Miami Florida 33145
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="copyrigth d-flex">
          <div className="copyrigth-content">
            <p className="copyrigth-text font-45 txt-bold">
              Copyright 2023 Bondi, and All Rights Reserved.
            </p>
          </div>
          <div className="copyrigth-image">
            <Link to={"https://www.charlieapps.com/"} target="_blank">
              {" "}
              <img src={charlieapps} />
            </Link>
          </div>
        </div>
      </footer>
    </>
  );
}
export { Footer };
